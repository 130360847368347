import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Flex, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentCustom } from '../../../components';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { usePrescriptionContext } from '../../../contexts/PrescriptionContext/PrescriptionContext';
import { useDownloadDocument } from '../../../utils/downloadDoc';
import { ConfigPrescription } from '../../../models/ConfigPrescription';
import { Prescription } from '../../../models/Prescription';
import { useAuthContext } from '../../../contexts/AuthContext';
import { Cards } from './Cards';
import { ProgressModal } from '../../../components/Modals/ProgressModal';

/**
 * @component
 * @name CreateUpdateRadiologyPrescription
 * @description A component to create/update radiology prescription
 * @param {String} purpose - A string to check if the component is used for creation or update
 * @returns {JSX.Element} React component
 */
export const CreateUpdateRadiologyPrescription = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [form] = Form.useForm();
  const { printDocument } = useDownloadDocument();
  const { user } = useAuthContext();
  const { setPatientId, patientId, patientInfos } = usePrescriptionContext();
  const navigate = useNavigate();

  const [prescriptionType, setPrescriptionType] = useState(null);
  const [imagingData, setImagingData] = useState({});
  const [data, setData] = useState({});
  const [locationValue, setLocationValue] = useState(null);
  const [effectsData, setEffectsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('active');

  const postPrescription = async ({ prescriptions_types, ...body }) => {
    setLoading(true);
    setStatus('active');

    const newBody = {
      ...body,
      purpose: 'IMAGING',
      prescription_type: prescriptions_types[0],
      location: body.location[0],
      patient: patientId,
      doctor: user._id,
      consultation: patientInfos?.infos?.current_consultation?._id
    };

    const response = await Prescription.postPrescription(newBody);
    if (response && response._id) {
      setTimeout(async () => {
        await printDocument(response);
        form.resetFields();
        navigate(-1);
      }, 500);
    }

    if (!response) {
      setLoading(false);
      setStatus('exception');
      return;
    }

    setLoading(false);
    setStatus('success');
  };

  useEffect(() => {
    setPatientId(id);
  }, [id]);

  useEffect(() => {
    (async () => {
      const response = await ConfigPrescription.getImagingTreeData();

      const { prescription_type, ...rest } = response;
      setImagingData(response);
      setData(rest);
    })();
  }, []);

  const laterality = () => {
    if (data?.laterality?.includes(locationValue)) {
      return true;
    }
    return false;
  };

  const locations = data.location?.filter((item) =>
    prescriptionType?.includes(item.prescription_type)
  );

  useEffect(() => {
    const effects = () =>
      data?.effects?.filter((effect) =>
        effect.prescription_wording.includes(locationValue)
      );

    setEffectsData(effects());
  }, [locationValue]);

  return (
    <ContentCustom>
      <PageHeaderCustom
        title={t('prescription.radiology.form.title', {
          first_name: '',
          last_name: ''
        })}
      />
      <Form onFinish={postPrescription} form={form}>
        <Cards
          imagingData={imagingData}
          prescriptionType={prescriptionType}
          setPrescriptionType={setPrescriptionType}
          locations={locations}
          locationValue={locationValue}
          setLocationValue={setLocationValue}
          laterality={laterality}
          effectsData={effectsData}
          form={form}
        />
        <Flex justify="end" style={{ marginTop: '16px' }} gap={8}>
          <Button onClick={() => navigate(-1)}>{t('buttons.cancel')}</Button>
          <Button htmlType="submit">{t('buttons.validate')}</Button>
        </Flex>
      </Form>
      <ProgressModal
        loading={loading}
        status={status}
        message="prescription.create"
      />
    </ContentCustom>
  );
};
