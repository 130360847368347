import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';
import { useErrorMessage } from '../../../../../utils/errorMessage';

/**
 * @hook
 * Hook to handle actions in the consultation context.
 *
 * @param {Object} params - The parameters passed to the hook.
 * @param {Function} params.setConsultation - Function to set the consultation data.
 * @param {Function} params.setDiagnostic - Function to set the diagnostic data.
 * @param {Function} params.setSpecialistConsultation - Function to set the specialist consultation data.
 * @param {Function} params.setIsDisableEdit - Function to set the disable edit state.
 * @param {string} params.idConsultation - The id of the consultation.
 * @param {Object} params.patient - The patient data.
 *
 * @returns {Object} An object containing the handleDateChange function.
 * @property {Function} handleDateChange - Function to handle the date change.
 */
export const useHandleActions = ({
  setConsultation,
  setIsDisableEdit,
  idConsultation,
  patient,
  setLoading
}) => {
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { printDocument } = useDownloadDocument();

  const handleDateChange = (date) => {
    const selectedConsultation = patient?.consultations?.find(
      (consult) => consult._id === date
    );
    setConsultation(selectedConsultation.consultation);
    if (selectedConsultation._id !== idConsultation) {
      setIsDisableEdit(true);
    } else {
      setIsDisableEdit(false);
    }
  };

  const handleTodayDate = (consultation) => {
    if (consultation) {
      setConsultation(consultation?.consultation);
      setIsDisableEdit(false);
    }
  };

  const handlePrintMedicalReport = async () => {
    try {
      setLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `consultations/${idConsultation}`
      });
      const { data: medicalReportId } = await dispatchAPI('POST', {
        url: `medical-reports/${patient?._id}`,
        body: {
          consultation: data?.consultation,
          diagnostic: data?.diagnostic,
          patient: data?.patient,
          doctor: user?._id,
          specialist_consultation: data?.specialist_consultation
        }
      });

      setTimeout(async () => {
        await printDocument(medicalReportId);
      }, 1000);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message(error);
    }
  };

  return {
    handleDateChange,
    handlePrintMedicalReport,
    handleTodayDate
  };
};
