import PropTypes from 'prop-types';
import { Flex } from 'antd';
import { Allergies } from './patientIcons/Allergies';
import { BloodSampling } from './patientIcons/BloodSampling';
import { CareIcon } from './patientIcons/CareIcon';
import { DoctorsLetter } from './patientIcons/DoctorsLetter';
import { IntravenousIcon } from './patientIcons/IntravenousIcon';
import { Pill } from './patientIcons/Pill';
import { Syringe } from './patientIcons/Syringe';
import { Tablet } from './patientIcons/Tablet';
import { Tube } from './patientIcons/Tube';
import { Stethoscope } from './patientIcons/Stethoscope';

/**
 * Renders the patient icons component.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Object} props.consultation - The consultation object.
 * @param {Object} props.patient - The patient object.
 * @param {Function} props.setRefresh - The function to refresh the component.
 * @param {boolean} props.isAllergic - Indicates if the patient is allergic.
 * @param {Object} props.prescriptionTypesEnums - The prescription types enums.
 * @param {Array} props.grounds - The grounds array.
 * @returns {JSX.Element} The rendered component.
 */
export const PatientIcons = ({
  consultation,
  patient,
  setRefresh,
  isAllergic,
  prescriptionTypesEnums,
  grounds
}) => (
  <Flex align="center" gap="small">
    <IntravenousIcon
      isAllergic={isAllergic}
      consultationId={patient?.current_consultation}
      setRefresh={setRefresh}
      consultation={consultation}
    />
    <BloodSampling
      consultation={consultation}
      setRefresh={setRefresh}
      prescriptionTypesEnums={prescriptionTypesEnums}
    />
    <Tablet
      patient={patient}
      consultation={consultation}
      setRefresh={setRefresh}
    />
    <Syringe
      patient={patient}
      consultation={consultation}
      setRefresh={setRefresh}
    />
    <Tube
      consultation={consultation}
      setRefresh={setRefresh}
      prescriptionTypesEnums={prescriptionTypesEnums}
    />
    <Stethoscope consultation={consultation} />
    <Allergies grounds={grounds} noAllergies={patient?.no_allergies} />
    <CareIcon consultation={consultation} setRefresh={setRefresh} />
    <DoctorsLetter patient={patient} />
    <Pill patient={patient} />
  </Flex>
);

PatientIcons.propTypes = {
  consultation: PropTypes.shape({}).isRequired,
  patient: PropTypes.shape({
    not_current_medications: PropTypes.arrayOf(PropTypes.string),
    current_consultation: PropTypes.string,
    no_allergies: PropTypes.bool
  }).isRequired,
  setRefresh: PropTypes.func.isRequired,
  isAllergic: PropTypes.func.isRequired,
  prescriptionTypesEnums: PropTypes.shape({}).isRequired,
  grounds: PropTypes.shape({}).isRequired
};
