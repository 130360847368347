import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Row, Select, Space, Tooltip } from 'antd';
import {
  EditOutlined,
  PrinterOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { useConsultationsContext } from '../../ConsultationsContext';
import { ModalMedicalConsultation } from './ModalMedicalConsultation';
import { useSetOptions } from './utils/useSetOptions';
import { RowConsultation } from './rowsList/RowConsultation';
import { RowDiagnostic } from './rowsList/RowDiagnostic';
import { RowSpecialistConsultation } from './rowsList/RowSpecialistConsultation';
import { useHandleActions } from './utils/useHandleActions';
import { LoadingModal } from '../../../../components/Modals/LoadingModal';
import { RowWorkAccident } from './rowsList/RowWorkAccident';
import { RowCCMUClassification } from './rowsList/RowCCMUClassification';

/**
 * A React component to display the details of a medical consultation.
 *
 * @component
 * @param {Object} props - Props to pass into the component.
 * @param {string} [props.id] - The ID of the medical consultation to display. Default value is `undefined`.
 *
 * @returns {React.ElementType} Returns a rendered card containing details of the medical consultation.
 */

export const ShowMedicalConsultation = ({ id }) => {
  const [consultation, setConsultation] = useState('');
  const [isDisableEdit, setIsDisableEdit] = useState(true);
  const [isModal, setModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const resource = 'consultations';

  const { t } = useTranslation();
  const {
    patient,
    getPatient,
    enums: { ccmu_classifications: ccmuClassificationsEnums }
  } = useConsultationsContext();

  const todayConsultation = patient?.current_consultation;
  const idConsultation = patient?.current_consultation?._id;
  const dateFormat = 'DD-MM-YYYY';

  const { diagnostic, description, work_accident, ccmu_classification } =
    consultation || {};

  const {
    current_consultation: {
      _id: currentConsultationId,
      consultation: { specialist_consultation } = {}
    } = {}
  } = patient || {};

  const { handlePrintMedicalReport, handleDateChange, handleTodayDate } =
    useHandleActions({
      setConsultation,
      setIsDisableEdit,
      idConsultation,
      patient,
      setLoading
    });

  useEffect(() => {
    (async () => {
      if (refresh) {
        await getPatient(id);
        setRefresh(false);
      }
    })();
  }, [refresh]);

  const { options } = useSetOptions({ setSelectedDate, handleDateChange });

  const redirectToOmniDoc = () => {
    window.open('https://app.omnidoc.fr/login)', '_blank');
  };

  return (
    <Card
      xs={24}
      md={8}
      lg={6}
      xl={6}
      title={t(`${resource}.form.medical_consultation`)}
      style={{ height: 'fit-content' }}
      extra={
        <Space>
          <Button
            onClick={() => {
              handleTodayDate(todayConsultation);
              setSelectedDate(dayjs(todayConsultation.date).format(dateFormat));
            }}
          >
            <Tooltip
              title={t(`consultations.medical_consultation.consultation_date`)}
              overlayInnerStyle={{ textAlign: 'center' }}
            >
              <CalendarOutlined />
            </Tooltip>
          </Button>
          <Select
            options={options}
            value={selectedDate}
            onChange={(value) => {
              setSelectedDate(value);
              if (handleDateChange) {
                handleDateChange(value);
              }
            }}
          />
          {!isDisableEdit && (
            <Button
              type="Link"
              icon={<EditOutlined />}
              onClick={() => setModal(true)}
            />
          )}
          <Button
            type="Link"
            onClick={handlePrintMedicalReport}
            icon={<PrinterOutlined />}
          />
        </Space>
      }
    >
      <ModalMedicalConsultation
        id={id}
        resource={resource}
        onDateChange={handleDateChange}
        idConsultation={idConsultation}
        isDisableEdit={isDisableEdit}
        isModal={isModal}
        setModal={setModal}
        patient={patient}
        consultation={consultation}
        setConsultation={setConsultation}
      />
      <Row gutter={[30, 30]}>
        <RowConsultation
          t={t}
          resource={resource}
          consultation={description}
          setConsultation={setConsultation}
        />
        <RowDiagnostic resource={resource} t={t} diagnostic={diagnostic} />
        <RowSpecialistConsultation
          resource={resource}
          t={t}
          specialistConsultation={specialist_consultation}
          redirectToOmniDoc={redirectToOmniDoc}
        />
        <RowWorkAccident
          currentConsultationId={currentConsultationId}
          workAccident={work_accident}
        />
        {consultation && (
          <RowCCMUClassification
            currentConsultationId={currentConsultationId}
            ccmuClassificationsEnums={ccmuClassificationsEnums}
            ccmuClassification={ccmu_classification}
            setRefresh={setRefresh}
          />
        )}
      </Row>
      <LoadingModal
        loading={loading}
        customTitle={t('modals.medical_report')}
        customMessage={t('modals.generate_document')}
      />
    </Card>
  );
};

ShowMedicalConsultation.propTypes = {
  id: PropTypes.string
};
ShowMedicalConsultation.defaultProps = {
  id: undefined
};
