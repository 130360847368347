import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Button, Checkbox, Input } from 'antd';
import { usePatchApiCalls } from '../../../patchConsultationApiCalls';
import { useAuthContext } from '../../../../../../contexts/AuthContext';

import useFields from '../../../../fieldsListPatients';

const { TextArea } = Input;
const { Item } = Form;

/**
 * Renders a form for adding comments to a patient report.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.record - The patient record.
 * @param {Function} props.setOpenModal - The function to open or close the modal.
 * @returns {JSX.Element} The JSX element representing the report comments form.
 */
export const ReportComments = ({ record, setOpenModal }) => {
  const id = record._id;
  const resource = 'patients';
  const [comment, setComment] = useState('');
  const { user } = useAuthContext();
  const [form] = Form.useForm();
  const { handleSubmit } = usePatchApiCalls(resource, id);
  const { t } = useTranslation();
  const { reportListDropDown } = useFields();
  const author = `${user.first_name} ${user.last_name}`;

  const placeholder = t(`${resource}.form.add_comment`);
  const fields = reportListDropDown;

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmitAndCloseModal = async (values) => {
    await handleSubmit(values);
    setOpenModal({ key: null, title: '', content: null });
  };

  useEffect(() => {
    form.setFieldsValue({
      reported: record.reported,
      written_by: `${author}`
    });
  }, [record]);

  return (
    <Form form={form} onFinish={(values) => handleSubmitAndCloseModal(values)}>
      <Row>
        <span>{t(`${resource}.list.dropdown.modal_report_patient`)}</span>
      </Row>
      {fields?.map((field) => (
        <Item
          key={field.name}
          label={
            field.label !== undefined ? t(`patients.form.${field.label}`) : null
          }
          name={field.name}
          valuePropName={field.name === 'reported' ? 'checked' : 'value'}
          rules={field.rules}
        >
          {(() => {
            switch (field.name) {
              case 'reported':
                return <Checkbox />;
              case 'reported_comment_reception':
                return (
                  <TextArea
                    value={comment}
                    onChange={handleChange}
                    placeholder={placeholder}
                    variant="outlined"
                    style={{ resize: 'none' }}
                  />
                );
              case 'written_by':
                return <Input disabled />;
              default:
                return null;
            }
          })()}
        </Item>
      ))}
      <Row justify="end">
        <Button
          key="cancel"
          onClick={() => setOpenModal({ key: null, content: null })}
          style={{
            borderRadius: '15px',
            margin: '2px'
          }}
        >
          {t('buttons.cancel')}
        </Button>
        <Button
          type="add"
          htmlType="submit"
          style={{
            borderRadius: '15px',
            margin: '2px'
          }}
        >
          {t('buttons.validate')}
        </Button>
      </Row>
    </Form>
  );
};

ReportComments.propTypes = {
  record: PropTypes.shape({
    _id: PropTypes.string,
    reported: PropTypes.bool
  }),
  setOpenModal: PropTypes.func.isRequired
};
ReportComments.defaultProps = {
  record: {
    _id: undefined,
    reported: undefined
  }
};
