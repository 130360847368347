import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Radio, Button, Dropdown } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  ContentCustom,
  PageHeaderCustom,
  ListResource
} from '../../components';
import { useColumns } from './columns';
import { usePrescriptionContext } from '../../contexts/PrescriptionContext/PrescriptionContext';

const { Group } = Radio;

/**
 * @component ListFavoritesPrescriptions
 * @description ListFavoritesPrescriptions - List of favorites prescriptions
 * @returns {component} ListFavoritesPrescriptions
 */
export const ListFavoritesPrescriptions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const columns = useColumns();
  const { setPosologies, setEntities } = usePrescriptionContext();
  const [selectedOption, setSelectedOption] = useState('EXIT');

  /**
   * @object options
   * @description options - Options for the radio group.
   * @returns {object} options - Options for the radio group.
   */
  const options = [
    { label: t('prescription.favorites.list.exit'), value: 'EXIT' },
    { label: t('prescription.favorites.list.stay'), value: 'STAY' },
    { label: t('prescription.favorites.list.protocols'), value: 'PROTOCOLS' }
  ];

  /**
   * @object actions
   * @description actions - Actions to do when clicking on the dropdown menu items.
   * @returns {function} actions - If key is 1, navigate to medication prescription creation page, if key is 2, navigate to free text prescription creation page.
   */
  const actions = {
    'medication-prescription': () => {
      setPosologies([]);
      setEntities([]);
      navigate(`/favorites/create/medication/${selectedOption}`);
    },
    'free-text-prescription': () => {
      setPosologies([]);
      setEntities([]);
      navigate(`/favorites/create/free-text/${selectedOption}`);
    }
  };

  /**
   * @object items
   * @description items - Items for the dropdown menu.
   * @returns {object} items - Items for the dropdown menu.
   */
  const items = [
    {
      key: 'medication-prescription',
      label: t('prescription.favorites.buttons.new_medication_prescription')
    },
    {
      key: 'free-text-prescription',
      label: t('prescription.favorites.buttons.new_free_text_prescription')
    }
  ];

  /**
   * @function handleNewFavorite
   * @description handleNewFavorite - Handle the click on the dropdown menu items.
   * @param {object} e - Event object.
   * @returns {function} actions - Execute the action corresponding to the key. (Cf. actions object)
   */
  const handleNewFavorite = (e) => {
    actions[e.key]();
  };

  /**
   * @object items
   * @description items - Items for the dropdown menu.
   * @returns {object} items - Items for the dropdown menu.
   */
  const menuProps = {
    items,
    onClick: handleNewFavorite
  };

  return (
    <ContentCustom>
      <PageHeaderCustom title={t('prescription.favorites.list.title')} />
      <Row justify="space-between">
        <Col>
          <Group
            options={options}
            value={selectedOption}
            optionType="button"
            onChange={(e) => setSelectedOption(e.target.value)}
          />
        </Col>
        <Col>
          <Dropdown placement="bottomRight" menu={menuProps}>
            <Button type="primary">
              <PlusOutlined /> {t('buttons.create')}
            </Button>
          </Dropdown>
        </Col>
      </Row>
      <ListResource
        isPageHeaderCustom={false}
        withSearchBar={false}
        withUploadButton={false}
        withCreateButton={false}
        archiveAction
        editAction={false}
        deleteAction={false}
        showAction={false}
        onDoubleClickAction={false}
        extraQuery={`type=${selectedOption}&archived=false`}
        populate="authorized"
        columns={columns}
        resourceName="prescriptions/favorites"
      />
    </ContentCustom>
  );
};
