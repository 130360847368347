import {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useSuccessMessage } from '../../utils/successMessage';
import { translateTreeData } from '../../utils/translateTreeData';
import { GetEnums } from '../../utils/getEnums';
import { Prescription } from '../../models/Prescription';
import { Directory } from '../../models/Directory';

export const ConsultationsContext = createContext();

/**
 * Provides the context for consultations data.
 *
 * @component
 * @param {Object} props - The props of the component.
 * @param {Object} props.children - The children of the component.
 * @returns {JSX.Element} The ConsultationsContextProvider component.
 */
export const ConsultationsContextProvider = ({ children }) => {
  const [enums, setEnums] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [patient, setPatient] = useState({});
  const [forcedRefresh, setForcedRefresh] = useState(false);
  const [centerRooms, setCenterRooms] = useState(null);
  const [selectedVisitValue, setSelectedVisitValue] = useState('');
  const [position, setPosition] = useState('');
  const [biologyPrescriptions, setBiologyPrescriptions] = useState([]);
  const [directory, setDirectory] = useState([]);

  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { successMessage } = useSuccessMessage();
  const { getEnums } = GetEnums();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const idCenter = user?.selected_center || user?.default_center;

  const updateHyperalgesic = async (value) => {
    try {
      const { data } = await dispatchAPI('PATCH', {
        url: `/consultations/${patient?.current_consultation?._id}/hyperalgesic`,
        body: {
          hyperalgesic: value
        }
      });

      setPatient((prevPatient) => ({
        ...prevPatient,
        current_consultation: {
          ...prevPatient.current_consultation,
          hyperalgesic: data.hyperalgesic
        }
      }));
      successMessage('consultation.hyperalgesic_updated');
    } catch (e) {
      message(e);
    }
  };

  const deleteComment = async (commentId) => {
    try {
      const { data } = await dispatchAPI('DELETE', {
        url: `/consultations/${patient?.current_consultation?._id}/medical-comments/${commentId}`
      });

      setPatient((prevPatient) => ({
        ...prevPatient,
        current_consultation: {
          ...prevPatient.current_consultation,
          medical_comments: data
        }
      }));
    } catch (error) {
      message(error);
    }
  };

  const checkComment = async (commentId) => {
    try {
      const { data } = await dispatchAPI('PATCH', {
        url: `/consultations/${patient?.current_consultation?._id}/medical-comments/${commentId}`
      });

      setPatient((prevPatient) => ({
        ...prevPatient,
        current_consultation: {
          ...prevPatient.current_consultation,
          medical_comments: data
        }
      }));
    } catch (error) {
      setPatient((prevPatient) => ({
        ...prevPatient
      }));
      message(error);
    }
  };

  const fetchAndTranslateTreeData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/visit-reasons/tree-data`
      });

      const translatedTreeData = translateTreeData(
        data,
        'data_bases.visit-reasons.categories',
        t,
        1
      );

      setTreeData(translatedTreeData);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPatient = useCallback(async (id) => {
    setIsLoading(true);

    const population = [
      'documentsIds',
      'constants_survey',
      'previous_consultations',
      'previous_consultations.visit_reasons',
      'current_consultation',
      'current_consultation.visit_reasons',
      'current_consultation.consultation.specialist_consultation',
      'current_consultation.medical_comments',
      'current_consultation.medical_comments.written_by',
      'constants_survey',
      'paramedicals_transmissions.nurse',
      'administrative_comments.written_by',
      'doctors_letters.doctors_letter_document',
      'treatment_prescriptions.treatment_prescriptions_document'
    ];

    try {
      const { data } = await dispatchAPI('GET', {
        url: `/patients/${id}?populate=${population.join(',')}`
      });

      setPatient(data);
    } catch (e) {
      if (e.response.status === 403) {
        navigate('/');
      }
      message(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchCenter = async () => {
    const population = [
      'first_visit_waiting_room',
      'return_visit_waiting_room'
    ];

    try {
      const { data } = await dispatchAPI('GET', {
        url: `/centers/${idCenter}?populate=${population.join(',')}`
      });
      return data;
    } catch (e) {
      if (e.response) message(e.response.status);
      return null;
    }
  };
  const fetchEnumsAndRooms = async () => {
    setIsLoading(true);
    try {
      const [enumsPatients, enumsConsultations, enumsConstants, rooms] =
        await Promise.all([
          getEnums('patients'),
          getEnums('consultations'),
          getEnums('constants'),
          fetchCenter()
        ]);
      const allEnums = {
        ...enumsPatients,
        ...enumsConsultations,
        ...enumsConstants
      };
      setEnums(allEnums);
      setCenterRooms(rooms);
      setIsLoading(false);
      return null;
    } catch (e) {
      if (e.response) message(e.response.status);
      return null;
    }
  };

  const getBiologiesPrescriptions = async () => {
    const response = await Prescription.getBiologiesPrescriptions({
      category: 'BIOLOGY',
      populate: 'prescription_document'
    });
    setBiologyPrescriptions(response);
  };

  const getDirectory = async () => {
    const response = await Directory.getAllDirectoriesByCenter({
      center: user?.selected_center || user?.default_center,
      follow_up_care: true
    });
    setDirectory(response);
  };

  useEffect(() => {
    (async () => {
      await Promise.all([
        fetchEnumsAndRooms(),
        fetchAndTranslateTreeData(),
        getBiologiesPrescriptions(),
        getDirectory()
      ]);
    })();
    if (user) {
      setPosition(user?.position);
    }
  }, []);

  return (
    <ConsultationsContext.Provider
      value={{
        enums,
        treeData,
        isLoading,
        setIsLoading,
        patient,
        getPatient,
        forcedRefresh,
        setForcedRefresh,
        centerRooms,
        setCenterRooms,
        selectedVisitValue,
        setSelectedVisitValue,
        position,
        setPosition,
        biologyPrescriptions,
        directory,
        deleteComment,
        checkComment,
        updateHyperalgesic
      }}
    >
      {children}
    </ConsultationsContext.Provider>
  );
};

export const useConsultationsContext = () => useContext(ConsultationsContext);
