import PropTypes from 'prop-types';
import { Row, Card, Col, Button, Flex } from 'antd';
import { useState } from 'react';
import { PrinterOutlined } from '@ant-design/icons';
import { EditButton } from './GeneralInformations/EditButton';
import { DMPButton } from './GeneralInformations/DMPButton';
import { useListContentTop } from './GeneralInformations/GeneralInfosListContentTop';
import { useListContentBottom } from './GeneralInformations/GeneralInfosListContentBottom';
import { TitleInfos } from './GeneralInformations/TitleInfos';
import { useConsultationsContext } from '../ConsultationsContext';
import { useAuthContext } from '../../../contexts/AuthContext';
import { DescriptionList } from '../../../components';
import { useDocumentSubmit } from './Documents/utils/useDocumentSubmit';
import { ProgressModal } from '../../../components/Modals/ProgressModal';
import { HyperalgesticButton } from './HyperalgesticButton';

/**
 * ShowGeneralInfos component displays general information about a patient.
 *
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.id - The ID of the patient.
 *
 * @returns {JSX.Element} The rendered component.
 */
export const ShowGeneralInfos = ({ id }) => {
  const { patient } = useConsultationsContext();
  const { user } = useAuthContext();
  const listContentTop = useListContentTop(patient);
  const listContentBottom = useListContentBottom(patient);
  const position = user?.position;
  const [status, setStatus] = useState('active');
  const [loading, setLoading] = useState(false);
  const { handleSubmit } = useDocumentSubmit({
    patientId: patient._id,
    documentType: `patients/patients-record`,
    setLoading,
    setStatus
  });

  return (
    <>
      <ProgressModal
        loading={loading}
        status={status}
        message="consultation.generate_administrative_file"
      />
      <Card
        style={{ position: 'relative' }}
        title={<TitleInfos />}
        extra={
          <Flex gap={6}>
            <Button onClick={handleSubmit}>
              <PrinterOutlined />
            </Button>
            <EditButton id={id} position={position} />
            <DMPButton />
          </Flex>
        }
      >
        <Row style={{ flexWrap: 'nowrap' }}>
          <Col span={16}>
            <DescriptionList
              data={listContentTop}
              layout="vertical"
              translate
            />
          </Col>
          <Col
            span={8}
            style={{
              bottom: 0,
              right: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <DescriptionList
              data={listContentBottom}
              layout="vertical"
              translate
            />
            <Flex justify="end">
              <HyperalgesticButton />
            </Flex>
          </Col>
        </Row>
      </Card>
    </>
  );
};

ShowGeneralInfos.propTypes = {
  id: PropTypes.string.isRequired
};
