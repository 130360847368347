import PropTypes from 'prop-types';
import { Col, Card } from 'antd';
import { PreviousComments } from '../../../../components/PreviousComments/PreviousComments';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { usePatientContext } from '../../../../contexts/PatientContext/PatientContext';

const resource = 'patients';

export const CollapseCommentsForm = ({ fields, comments, commentType }) => {
  const generateFields = useGenerateFormItem();
  const { deleteComment, checkComment } = usePatientContext();

  return (
    <Col>
      {comments
        ? comments.map((data) => (
            <PreviousComments
              key={data[commentType] + data.written_by}
              written_by={data.written_by}
              date={data.date}
              comment={data}
              deleteComment={deleteComment}
              checkComment={checkComment}
            />
          ))
        : null}
      <Card>{fields.map((field) => generateFields(resource, field))}</Card>
    </Col>
  );
};

CollapseCommentsForm.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({})),
  commentType: PropTypes.string.isRequired
};
CollapseCommentsForm.defaultProps = {
  comments: undefined
};
