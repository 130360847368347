import { useTranslation } from 'react-i18next';
import { Card, Flex, Typography } from 'antd';
import { useConsultationsContext } from '../ConsultationsContext';
import { getVisitReasons } from '../../../utils/formatters';

const { Text } = Typography;

/**
 * Component to display entry notifications for a patient.
 * @component
 * @returns {JSX.Element} The rendered component.
 */
export const ShowEntryNotifications = () => {
  const resource = 'consultations';
  const { t } = useTranslation();
  const { patient } = useConsultationsContext();
  const comment =
    patient?.current_consultation?.entry_notification?.description;
  const visitReasons = patient?.current_consultation?.visit_reasons;

  return (
    <Card>
      <Flex>
        <h1>{t(`${resource}.entry_reason`)}</h1>
      </Flex>
      <Flex vertical>
        <Text>{getVisitReasons(visitReasons)}</Text>
        <Text>{comment}</Text>
      </Flex>
    </Card>
  );
};
