import { Card, Checkbox, Col, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const { TextArea } = Input;

/**
 * Renders a set of cards for the Radiology Prescription form.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.imagingData - The data for imaging.
 * @param {string} props.prescriptionType - The selected prescription type.
 * @param {function} props.setPrescriptionType - The function to set the prescription type.
 * @param {Array} props.locations - The available locations.
 * @param {string} props.locationValue - The selected location value.
 * @param {function} props.setLocationValue - The function to set the location value.
 * @param {function} props.laterality - The function to check if laterality is available.
 * @param {Array} props.effectsData - The data for effects.
 * @param {Object} props.form - The form object.
 * @returns {JSX.Element} The rendered component.
 */
export const Cards = ({
  imagingData,
  prescriptionType,
  setPrescriptionType,
  locations,
  locationValue,
  setLocationValue,
  laterality,
  effectsData,
  form
}) => {
  const { t } = useTranslation();

  const cardStyles = {
    body: {
      height: '100%'
    }
  };

  const renderTitle = (title) => (
    <div style={{ position: 'relative', paddingBottom: '8px' }}>
      <span>{title}</span>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '40px',
          height: '4px',
          backgroundColor: 'var(--secondaryColor)',
          borderBottomRightRadius: '4px',
          borderTopRightRadius: '4px'
        }}
      />
    </div>
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '70vh',
        gap: '24px'
      }}
    >
      <Card
        style={{
          backgroundColor: 'var(--primaryHover)',
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto'
        }}
      >
        <Row gutter={[24, 24]} style={{ flex: '1 1 auto', height: '100%' }}>
          <Col
            xs={24}
            md={24}
            lg={6}
            style={{ display: 'flex', height: '100%' }}
          >
            <Card
              title={renderTitle(
                t('prescription.radiology.form.card_titles.prescriptions_types')
              )}
              style={{
                border: 'none',
                flex: '1 1 auto',
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
              }}
              styles={cardStyles}
            >
              <Form.Item
                name="prescriptions_types"
                style={{ flex: '1 1 auto', height: '100%' }}
              >
                <Checkbox.Group
                  style={{
                    flex: '1 1 auto',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  {imagingData?.prescriptions_types?.map(
                    (prescription_type) => (
                      <Checkbox
                        key={prescription_type}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setPrescriptionType(e.target.value);
                          } else {
                            setPrescriptionType(null);
                          }
                        }}
                        disabled={
                          prescriptionType !== null &&
                          prescription_type !== prescriptionType
                        }
                        value={prescription_type}
                      >
                        {t(
                          `data_bases.stay_prescriptions.columns.prescription_types.${prescription_type}`
                        )}
                      </Checkbox>
                    )
                  )}
                </Checkbox.Group>
              </Form.Item>
            </Card>
          </Col>
          <Col xs={24} md={24} lg={6} style={{ display: 'flex' }}>
            <Card
              title={renderTitle(
                t('prescription.radiology.form.card_titles.location')
              )}
              style={{
                border: 'none',
                flex: '1 1 auto',
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
              }}
              styles={cardStyles}
            >
              {locations && (
                <Form.Item
                  name="location"
                  style={{
                    flex: '1 1 auto',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Checkbox.Group
                    style={{
                      flex: '1 1 auto',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    {locations.map((location) => (
                      <Checkbox
                        key={location.value}
                        disabled={
                          locationValue !== null &&
                          location.value !== locationValue
                        }
                        value={location.value}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setLocationValue(e.target.value);
                          } else {
                            setLocationValue(null);
                            form.resetFields(['laterality', 'effects']);
                          }
                        }}
                      >
                        {location.label}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              )}
            </Card>
          </Col>
          <Col xs={24} md={24} lg={6} style={{ display: 'flex' }}>
            <Card
              title={renderTitle(
                t('prescription.radiology.form.card_titles.laterality')
              )}
              style={{
                border: 'none',
                flex: '1 1 auto',
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
              }}
            >
              {laterality() && (
                <Form.Item
                  name="laterality"
                  style={{ flex: '1 1 auto', height: '100%' }}
                >
                  <Checkbox.Group
                    options={[
                      {
                        label: t('prescription.radiology.form.lateral.left'),
                        value: 'left'
                      },
                      {
                        label: t('prescription.radiology.form.lateral.right'),
                        value: 'right'
                      },
                      {
                        label: t(
                          'prescription.radiology.form.lateral.bilateral'
                        ),
                        value: 'bilateral'
                      }
                    ]}
                    style={{
                      flex: '1 1 auto',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  />
                </Form.Item>
              )}
            </Card>
          </Col>
          <Col xs={24} md={24} lg={6} style={{ display: 'flex' }}>
            <Card
              title={renderTitle(
                t('prescription.radiology.form.card_titles.effects')
              )}
              style={{
                border: 'none',
                flex: '1 1 auto',
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
              }}
            >
              {effectsData?.length > 0 && (
                <Form.Item name="effects" style={{ flex: '1 1 auto' }}>
                  <Checkbox.Group
                    options={effectsData}
                    style={{
                      flex: '1 1 auto',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  />
                </Form.Item>
              )}
            </Card>
          </Col>
        </Row>
      </Card>
      <Card
        title={renderTitle(t('prescription.radiology.form.comment'))}
        style={{ flex: '1 1 auto', height: '50%' }}
      >
        <Form.Item name="comment" style={{ height: '100%' }}>
          <TextArea
            rows={4}
            style={{ backgroundColor: '#F2F2F2', height: '100%' }}
          />
        </Form.Item>
      </Card>
    </div>
  );
};

Cards.propTypes = {
  imagingData: PropTypes.shape({
    prescriptions_types: PropTypes.arrayOf(PropTypes.string)
  }),
  prescriptionType: PropTypes.string,
  setPrescriptionType: PropTypes.func,
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  locationValue: PropTypes.string,
  setLocationValue: PropTypes.func,
  laterality: PropTypes.func,
  effectsData: PropTypes.arrayOf(PropTypes.shape({})),
  form: PropTypes.shape({
    resetFields: PropTypes.func
  })
};

Cards.defaultProps = {
  imagingData: {},
  prescriptionType: '',
  setPrescriptionType: () => {},
  locations: [],
  locationValue: '',
  setLocationValue: () => {},
  laterality: () => {},
  effectsData: [],
  form: {}
};
