import { Protocol } from '../../../models/Protocol';

/**
 * Custom hook for posting favorites.
 * @hook
 *
 * @param {Object} options - The options for the hook.
 * @param {Object} options.form - The form object.
 * @param {Function} options.setRefresh - The function to set the refresh state.
 * @param {boolean} options.refresh - The refresh state.
 * @returns {Object} - The postFavorite function.
 */
export const usePostFavorite = ({ form, setRefresh, refresh }) => {
  /**
   * Posts a favorite to the server.
   *
   * @param {string} category - The category of the favorite.
   * @returns {Promise<void>} - A promise that resolves when the favorite is posted.
   */
  const postFavorite = async (category) => {
    let body;
    const categoryData = form.getFieldValue(category);

    if (category === 'GLOBAL') {
      body = form.getFieldsValue();
    } else {
      body = { [category]: categoryData };
    }

    await Protocol.createFavorite(category, body);

    setRefresh(!refresh);
  };

  return { postFavorite };
};
