const useFields = () => {
  const administrativeCommentsListDropdown = [
    {
      noLabel: true,
      name: 'written_by',
      rules: [{ required: false }]
    },
    {
      label: 'description',
      name: 'description',
      rules: [{ required: false }]
    }
  ];

  const reportListDropDown = [
    {
      label: 'report',
      name: 'reported',
      rules: [{ required: false }]
    },
    {
      noLabel: true,
      name: 'written_by',
      rules: [{ required: false }]
    },

    {
      label: 'comment',
      name: 'reported_comment_reception',
      rules: [{ required: false }]
    }
  ];
  return { reportListDropDown, administrativeCommentsListDropdown };
};

export default useFields;
