import { useState } from 'react';
import { Col, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { usePrescriptionContext } from '../../../contexts/PrescriptionContext/PrescriptionContext';
import { useValidateChoices } from '../stay/utils/useValidateChoices';
import { useHandleClick } from '../stay/utils/useHandleClick';
import { getFullName } from '../../../utils/formatters';
import { FavoritesCard } from '../stay/cards/FavoritesCard';
import { ProtocolsCard } from './ProtocolsCard';
import { TreeCard } from '../stay/cards/TreeCard';
import { MedicationCard } from './MedicationCard';
import { ExtraButtons } from '../stay/ExtraButtons';
import { ModalValidateChoices } from '../stay/modals/ModalValidateChoices';
import { LoadingModal } from '../../../components/Modals/LoadingModal';
import { usePostFavorite } from '../utils/postFavorite';
import { FreePrescriptionCard } from './FreePrescriptionCard';
import { SRNContent } from './SRNContent';

/**
 * Renders the PrescriptionsContent component.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.type - The type of prescription.
 * @param {Function} props.handleNewFreeTextPrescription - The function to handle the creation of a new free text prescription.
 * @returns {JSX.Element} The rendered PrescriptionsContent component.
 */
export const PrescriptionsContent = ({
  type,
  handleNewFreeTextPrescription
}) => {
  const [globalCheckedKeys, setGlobalCheckedKeys] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [resetCheckbox, setResetCheckbox] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const {
    doctors,
    patientInfos,
    srnCareTreeKeys,
    setSrnCareTreeKeys,
    imagingTreeKeys,
    setImagingTreeKeys,
    biologyTreeKeys,
    setBiologyTreeKeys
  } = usePrescriptionContext();

  const { handleValidateChoices } = useValidateChoices({
    setLoading,
    patientInfos,
    srnCareTreeKeys,
    imagingTreeKeys,
    biologyTreeKeys,
    form,
    checkedKeys,
    type
  });

  const { handleClickFavoritePrescription } = useHandleClick({
    setCheckedKeys,
    setGlobalCheckedKeys,
    category: 'GLOBAL',
    form
  });

  const doctorsSelectOptions = doctors?.map((doctor) => ({
    label: getFullName(doctor),
    value: doctor._id
  }));

  const { postFavorite } = usePostFavorite({
    form,
    setRefresh,
    refresh
  });

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} className="column">
          <Row className="row-spacing">
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <FavoritesCard
                category="GLOBAL"
                form={form}
                setCheckedKeys={setGlobalCheckedKeys}
                setGlobalCheckedKeys={setGlobalCheckedKeys}
                editableRowHandleClick={handleClickFavoritePrescription}
                postFavorite={postFavorite}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <ProtocolsCard
                className="ant-card "
                checkedKeys={globalCheckedKeys}
                setCheckedKeys={setGlobalCheckedKeys}
                setSrnCareTreeKeys={setSrnCareTreeKeys}
                setImagingTreeKeys={setImagingTreeKeys}
                setBiologyTreeKeys={setBiologyTreeKeys}
                resetCheckbox={resetCheckbox}
                setResetCheckbox={setResetCheckbox}
                form={form}
              />
            </Col>
          </Row>
          <Row className="row-spacing">
            <TreeCard
              form={form}
              setCheckedKeys={setCheckedKeys}
              globalCheckedKeys={globalCheckedKeys}
              category="BIOLOGY"
              setTreeKeys={setBiologyTreeKeys}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </Row>
          <Row className="row-spacing">
            <MedicationCard category="MEDICATIONS" />
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} className="column">
          <Row className="row-spacing">
            {type === 'STAY' ? (
              <SRNContent
                form={form}
                category="SRN_CARE"
                setCheckedKeys={setCheckedKeys}
                globalCheckedKeys={globalCheckedKeys}
                setTreeKeys={setSrnCareTreeKeys}
                refresh={refresh}
                setRefresh={setRefresh}
                postFavorite={postFavorite}
              />
            ) : (
              <FreePrescriptionCard
                refresh={refresh}
                setRefresh={setRefresh}
                handleNewFreeTextPrescription={handleNewFreeTextPrescription}
              />
            )}
          </Row>
          <Row className="row-spacing">
            <TreeCard
              form={form}
              setCheckedKeys={setCheckedKeys}
              globalCheckedKeys={globalCheckedKeys}
              category="IMAGING"
              setTreeKeys={setImagingTreeKeys}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </Row>
          {/* <Row>
            <Card title="Orthèse">Orthèse</Card>
          </Row> */}
        </Col>
      </Row>
      <ExtraButtons
        srnCareTreeKeys={srnCareTreeKeys}
        imagingTreeKeys={imagingTreeKeys}
        biologyTreeKeys={biologyTreeKeys}
        globalCheckedKeys={globalCheckedKeys}
        postFavorite={postFavorite}
        handleValidateChoices={handleValidateChoices}
        setOpenModa={setOpenModal}
      />
      {imagingTreeKeys.some((item) => item.scanner) && (
        <ModalValidateChoices
          setOpenModal={setOpenModal}
          openModal={openModal}
          handleValidateChoices={handleValidateChoices}
          doctorsSelectOptions={doctorsSelectOptions}
        />
      )}
      <LoadingModal
        loading={loading}
        customTitle={t('modals.generate_prescription_title')}
        customMessage={t('modals.generate_document')}
      />
    </>
  );
};

PrescriptionsContent.propTypes = {
  type: PropTypes.string.isRequired,
  handleNewFreeTextPrescription: PropTypes.func
};

PrescriptionsContent.defaultProps = {
  handleNewFreeTextPrescription: () => {}
};
